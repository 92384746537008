#sidebar-menu > ul > li > a.active-menu {
  background-color: #C8454A; /* #222d32 */
  color: rgba(255, 255, 255, 0.8);
}

.PhoneInputInput {
  display: block;
  padding: .375rem .75rem;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.PhoneInputCountryIconImg {
  margin-top: -10px;
}
.sortableHelper {
  z-index: 10000000;
}